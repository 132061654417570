import { useRef, useState } from "react";
import {
  ChevronDownSolid,
  DestructiveMenuActionConfirmation,
  FaviconIcon,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  ButtonBase,
  CircularProgress,
  ClickAwayListener,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { RulesAndThresholdsForm, TITLE_HEIGHT } from "./RulesAndThresholdsForm";
import { useReportTemplateAccumulator } from "../../../_common/hooks/useReportTemplateAccumulator";
import { ProjectAutocomplete } from "./ProjectAutocomplete";
import { GetAlertWithTestsQuery, Maybe, ModuleCode } from "../../../graphql";
import {
  RulesAndThresholdsFormState,
  SimpleTest,
} from "../../_common/utils/types";
import { FormikProps } from "formik";
import { PendoGuideIcon } from "../../_common/PendoGuideIcon";
import { DEFAULT_RULE_AND_THRESHOLDS } from "../constants";
import { useInitialThresholdCalculator } from "../../_common/utils/useInitialThresholdCalculator";
import { AlertOption, ProjectOption } from "../../../_common/utils/constants";
import { NotificationChanelSelector } from "../../_common/notification-channels/NotificationChanelSelector";
import { RulesAndThresholdsEditTable } from "../../_common/RulesAndThresholdsEditTable";

const FAVICON_SIZE = 24;
const ICON_SIZE = 20;
export const ALERT_LIST_WIDTH = 281;

interface Props {
  selectedProject: ProjectOption;
  onProjectChange?: (newValue: Maybe<AlertOption>) => void;
  loading?: boolean;
  title: string;
  initialValues?: RulesAndThresholdsFormState;
  originalAlert?: GetAlertWithTestsQuery;
  onFinished?: (data: RulesAndThresholdsFormState | null | undefined) => void;
  disableNotificationChanels?: boolean;
  isCopyingToExistingAlert: boolean;
  testList: SimpleTest[];
  enableReinitialize: boolean;
}

export function ChooseRulesAndThresholdsView({
  selectedProject,
  onProjectChange,
  loading: loadingProvided,
  title,
  initialValues,
  originalAlert,
  onFinished,
  disableNotificationChanels,
  isCopyingToExistingAlert,
  testList,
  enableReinitialize,
}: Props): JSX.Element {
  const classes = useStyles();

  const {
    reports,
    categories,
    error: templatesError,
    loading: loadingReports,
  } = useReportTemplateAccumulator({
    moduleCodes: [selectedProject.moduleCode],
    projectId: selectedProject.id,
  });

  const error = templatesError;

  const { t } = useTranslation("alerts");
  const theme = useTheme();

  const [isChangingProject, setIsChangingProject] = useState(false);
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  const canProjectBeChanged = Boolean(onProjectChange);

  const [newProject, setNewProject] = useState<Maybe<AlertOption> | undefined>(
    undefined,
  );

  const [
    { loading: loadingInitialData, value: defaultThreshold, firstLoad },
    calculateInitialThreshold,
  ] = useInitialThresholdCalculator(
    initialValues ? undefined : selectedProject.id,
    undefined,

    DEFAULT_RULE_AND_THRESHOLDS.find((e) =>
      e.supportedModules?.includes(selectedProject.moduleCode),
    )?.report.code,
  );

  const handleClickAway = (): void => {
    setIsChangingProject(false);
  };

  const handleButtonClick = (): void => {
    setIsChangingProject(true);
  };

  const handleAutocompleteChange = async (
    project: Maybe<AlertOption>,
  ): Promise<void> => {
    const hasSegments = formikRef.current?.values.rulesAndThresholds.find((e) =>
      Boolean(e.segment),
    );
    if (hasSegments) setNewProject(project);
    else handleProjectChange(project);
  };

  const handleProjectChange = async (
    project: Maybe<AlertOption>,
  ): Promise<void> => {
    const defaultRule = DEFAULT_RULE_AND_THRESHOLDS.find((e) =>
      e.supportedModules?.includes(project?.moduleCode ?? ModuleCode.Seo),
    );

    const filteredRulesAndThresholds =
      formikRef.current?.values.rulesAndThresholds.filter(
        (e) =>
          !Boolean(e.segment) &&
          !e.report.code.startsWith("custom_extraction_"),
      );
    const cleanRulesAndThresholds = filteredRulesAndThresholds?.length
      ? filteredRulesAndThresholds
      : defaultRule
        ? [defaultRule]
        : [];

    const values = await Promise.all(
      cleanRulesAndThresholds.map(async (value) => {
        return await calculateInitialThreshold(
          project?.id,
          undefined,
          value.report.code,
          value.threshold,
        );
      }),
    );

    formikRef.current?.setValues({
      ...formikRef.current?.values,
      rulesAndThresholds: cleanRulesAndThresholds.map((e, index) => ({
        ...e,
        urls: values[index] ?? 100,
      })),
    });

    setIsChangingProject(false);
    setNewProject(undefined);
    onProjectChange?.(project);
  };

  const loading = loadingProvided || loadingReports || loadingInitialData;

  const formikRef = useRef<FormikProps<RulesAndThresholdsFormState>>(null);

  return (
    <>
      <RulesAndThresholdsForm
        projectId={selectedProject.id}
        title={title}
        initialValues={initialValues}
        originalAlert={originalAlert}
        defaultThreshold={defaultThreshold ?? 100}
        onFinished={onFinished}
        isCopyingToExistingAlert={isCopyingToExistingAlert}
        enableReinitialize={enableReinitialize || firstLoad}
        formikRef={formikRef}
        moduleCode={selectedProject.moduleCode}
      >
        <div className={classes.container}>
          <div className={classes.sideContentContainer}>
            <Typography
              variant="subtitle2Medium"
              className={classes.sideContentTitle}
            >
              {t("alertDraft")}
            </Typography>
            <Typography
              variant="button"
              className={classes.sideContentSubtitle}
            >
              {canProjectBeChanged ? t("changeProject") : t("project")}
            </Typography>

            <ClickAwayListener onClickAway={handleClickAway}>
              <div ref={mainContentRef}>
                {isChangingProject && canProjectBeChanged ? (
                  <div style={{ display: "flex", flexFlow: "row" }}>
                    <div style={{ flex: 1 }}>
                      <ProjectAutocomplete
                        value={null}
                        onChange={handleAutocompleteChange}
                      />
                    </div>
                  </div>
                ) : (
                  <ButtonBase
                    className={classes.projectListItem}
                    onClick={handleButtonClick}
                    data-pendo="change-project-create-alert"
                    data-testid="change-project-create-alert"
                  >
                    <FaviconIcon
                      height={FAVICON_SIZE}
                      width={FAVICON_SIZE}
                      url={selectedProject.primaryDomain}
                    />
                    <div className={classes.nameAndDomainContainer}>
                      <Typography
                        variant="subtitle2SemiBold"
                        className={classes.projectName}
                        title={selectedProject.name}
                        noWrap
                      >
                        {selectedProject.name}
                      </Typography>
                      <Typography
                        noWrap
                        className={classes.primaryDomain}
                        title={selectedProject.primaryDomain}
                      >
                        {selectedProject.primaryDomain}
                      </Typography>
                    </div>
                    {canProjectBeChanged ? (
                      <ChevronDownSolid className={classes.icon} />
                    ) : undefined}
                  </ButtonBase>
                )}
              </div>
            </ClickAwayListener>
          </div>
          <div className={classes.mainContentContent}>
            <div
              style={{
                paddingTop: theme.spacing(3),
                background: "#F0F3F7",
                paddingBottom: theme.spacing(4),
                paddingLeft: theme.spacing(3),
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Typography
                  variant="subtitle2Medium"
                  style={{ marginRight: 8 }}
                >
                  {t("chooseRulesAndThresholdsTitle")}
                </Typography>
                <PendoGuideIcon />
              </div>
              <Typography variant="caption" className={classes.mainContentText}>
                {t("chooseRulesAndThresholdsDescription")}
              </Typography>
            </div>
            {loading ? (
              <CircularProgress data-testid="choose-rules-and-thresholds-loading" />
            ) : (
              <div
                style={{
                  background: "#F0F3F7",
                }}
              >
                {loadingInitialData ? (
                  <CircularProgress data-testid="choose-rules-and-thresholds-loading" />
                ) : undefined}
                <RulesAndThresholdsEditTable
                  className={classes.table}
                  reports={reports ?? []}
                  categories={categories ?? []}
                  hasReportsError={Boolean(error)}
                  projectId={selectedProject.id}
                  testList={testList}
                  crawlId={selectedProject.lastFinishedCrawl?.id}
                  moduleCode={selectedProject.moduleCode}
                />
                {!disableNotificationChanels ? (
                  <NotificationChanelSelector
                    key={String(
                      formikRef.current?.isSubmitting &&
                        formikRef.current?.isValid &&
                        !formikRef.current?.isValidating,
                    )}
                    isEditMode={true}
                    className={classes.selector}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </RulesAndThresholdsForm>

      {newProject ? (
        <DestructiveMenuActionConfirmation
          align="left"
          title={t("changeProjectDialogTitle")}
          description={t("changeProjectDialogDesc")}
          onConfirm={async () => {
            await handleProjectChange(newProject);
          }}
          onCancel={() => {
            setNewProject(undefined);
          }}
          confirmText={t("changeProjectDialogButtonChange")}
          pendoPrefix={"monitor-dashboard-header-menu-delete"}
          anchorEl={mainContentRef.current}
        />
      ) : undefined}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },

  sideContentContainer: {
    maxWidth: ALERT_LIST_WIDTH,
    flex: 1,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3),
    minHeight: `calc(100vh - ${TITLE_HEIGHT}px)`,
  },
  sideContentTitle: {
    fontWeight: 600,
    display: "block",
    marginBottom: 22,
    color: theme.palette.grey[800],
  },
  sideContentSubtitle: {
    marginBottom: 5,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[700],
    display: "block",
  },
  mainContentContent: {
    padding: 0,
    paddingRight: 1,
    flex: 1,
    minWidth: 0,
  },
  mainContentText: { display: "block" },
  projectListItem: {
    width: "100%",
    border: `1px solid ${theme.palette.ultraviolet[300]}`,
    background: theme.palette.ultraviolet[100],
    justifyContent: "flex-start",
    borderRadius: 8,
    minHeight: 59,
    paddingLeft: theme.spacing(2),
    paddingRight: 5,
    "&:hover": {
      background: theme.palette.ultraviolet[50],
    },
  },
  nameAndDomainContainer: {
    textAlign: "left",
    width: `calc(100% - ${FAVICON_SIZE + ICON_SIZE}px)`,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 5,
  },
  projectName: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    width: "100%",
  },
  primaryDomain: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  icon: {
    marginLeft: "auto",
    fontSize: ICON_SIZE,
    color: theme.palette.primary.main,
  },
  table: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  selector: {
    paddingRight: theme.spacing(3),
  },
}));
