import { useEffect, useMemo, useState } from "react";
import {
  Button,
  PlusSolid,
  TrashSolid,
  Typography,
  updateIfPropsChanged,
  useTranslation,
  useSession,
  Trans,
} from "@lumar/shared";
import {
  ModuleCode,
  ReportTemplateUnit,
  RoleCode,
  Segment,
  ThresholdPredicate,
} from "../../graphql";
import {
  TextField as MuiTextField,
  makeStyles,
  createStyles,
  Tooltip,
  IconButton,
  useTheme,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  FastField,
  Field,
  FieldArray,
  FormikErrors,
  useFormikContext,
} from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@material-ui/lab";
import { ReportTrendVisualisation } from "../create/components/ReportTrendVisualisation";
import {
  RuleAndThreshold,
  RulesAndThresholdsFormState,
  SimpleTest,
} from "./utils/types";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { ErrorBoundary } from "@rollbar/react";
import { SimpleFallbackError } from "../../_common/components/SimpleFallbackError";
import { SeverityButtons } from "./SeverityButtons";
import { ThresholdPredicateButtons } from "./ThresholdPredicateButtons";
import {
  FullReportCategory,
  ReportOption,
} from "../../_common/utils/constants";
import { LeftBottomPopper } from "../../_common/components/CustomPopper/LeftBottomPopper";
import { ThresholdAcceptanceMenu } from "./ThresholdAcceptanceMenu";
import { useInitialThresholdCalculator } from "./utils/useInitialThresholdCalculator";
import { ReportOptionElement } from "../../_common/components/reports/components/ReportOptionElement";
import { ReportOptionGroupElement } from "../../_common/components/reports/components/ReportOptionGroupElement";
import { TextFieldWithUnitField } from "./TextFieldWithUnit";
import { RulesAndThresholdsEditTableHeaders } from "./RulesAndThresholdsEditTableHeaders";
import clsx from "clsx";
import { SegmentComboBox } from "../../_common/components/SegmentComboBox";
import React from "react";
import { AnalyticsHubReportLink } from "../../board/dashboards/account-overview/AnalyticsHubReportLink";
import { useParams } from "react-router-dom";
import { DEFAULT_RULE_AND_THRESHOLDS } from "../create/constants";
import { useTotalRulesCount } from "./utils/useTotalRulesCount";
import { ConditionallTooltip } from "../../_common/components/ConditionalTooltip";
import { AbsoluteURLs } from "../../_common/routing/absoluteURLs";

const MAX_NUMBER_OF_RULES = 100;

export const URL_THRESHOLD_WIDTH = 100;
export const REPORT_COMBOBOX_MIN_WIDTH = 150;
export const REPORT_COMBOBOX_MAX_WIDTH = 500;

function getValidationError(
  errors: string | string[] | FormikErrors<RuleAndThreshold>[] | undefined,
  index: number,
): FormikErrors<RuleAndThreshold> | string | undefined {
  if (!errors) return undefined;

  if (typeof errors === "string") return errors;

  const reportError = errors[index];

  if (typeof reportError === "string") {
    return reportError;
  }
  return reportError;
}

export function RulesAndThresholdsEditTable(props: {
  reports: ReportOption[];
  categories: FullReportCategory[];
  hasReportsError: boolean;
  projectId: string;
  crawlId: string;
  className?: string;
  testList: SimpleTest[];
  moduleCode?: ModuleCode;
}): JSX.Element {
  const { values, errors } = useFormikContext<RulesAndThresholdsFormState>();

  const addPossibleTemplateList = props.reports.filter(
    (element) =>
      !Boolean(
        values.rulesAndThresholds.find((e) => e.report?.code === element?.code),
      ),
  );

  const { accountId } = useParams<{ accountId: string }>();
  const { maxRulesCount, totalRulesCount, loading, error } = useTotalRulesCount(
    accountId,
    props.projectId,
  );

  const session = useSession();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = session;

  const classes = useStyles();
  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
  const { t } = useTranslation(["alerts", "units", "common"]);
  const theme = useTheme();

  const [{}, calculateInitialThreshold] = useInitialThresholdCalculator();

  const possibleTemplateList = props.reports;

  useEffect(() => {
    if (scrollToBottom) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const readOnly = !session.hasSufficientRole(RoleCode.Editor);
  const admin = session.hasSufficientRole(RoleCode.Admin);

  const sortedList = useMemo(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    return [...(props.reports ?? [])].sort(
      (a, b) => (a.category?.position ?? 0) - (b.category?.position ?? 0),
    );
  }, [props.reports]);

  const accountLimitReached =
    totalRulesCount + values.rulesAndThresholds.length >= maxRulesCount;

  const disableAddButton = loading || Boolean(error) || accountLimitReached;

  return (
    <div className={props.className}>
      <div className={classes.scrollableContainer}>
        <FieldArray
          name="rulesAndThresholds"
          render={(arrayHelpers) => {
            const { setFieldTouched, setFieldValue } = arrayHelpers.form;
            const values = arrayHelpers.form
              .values as RulesAndThresholdsFormState;
            return (
              <>
                <div className={classes.wrapper}>
                  <Table className={classes.table}>
                    <RulesAndThresholdsEditTableHeaders />
                    <TableBody>
                      {values.rulesAndThresholds.map(
                        (ruleAndThreshold, index) => {
                          const reportsErrorMessage = getValidationError(
                            errors.rulesAndThresholds,
                            index,
                          );
                          const hasUniqueError =
                            typeof reportsErrorMessage === "string";
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                className={classes.tableRow}
                                data-testid={`rule-and-threshold-row-${index}`}
                                data-testid-2={`rule-and-threshold-row-${ruleAndThreshold.report?.name.replace(
                                  " ",
                                  "-",
                                )}${
                                  ruleAndThreshold.segment
                                    ? `-${ruleAndThreshold.segment?.name.replace(
                                        " ",
                                        "-",
                                      )}`
                                    : ""
                                }`}
                              >
                                <TableCell className={classes.rowCell}>
                                  <SeverityButtons
                                    isDisabled={readOnly}
                                    severity={ruleAndThreshold.severity}
                                    onSeverityChange={(newSeverity) => {
                                      const fieldName = `rulesAndThresholds[${index}].severity`;
                                      setFieldValue(fieldName, newSeverity);
                                      setFieldTouched(fieldName, true);
                                    }}
                                  />
                                </TableCell>
                                <TableCell className={classes.rowCell}>
                                  <FastField
                                    name={`rulesAndThresholds[${index}].report`}
                                    component={Autocomplete}
                                    options={sortedList}
                                    data-testid="rule-and-threshold-report-combobox"
                                    disableClearable
                                    disabled={readOnly}
                                    data-pendo="rule-and-threshold-report-combobox"
                                    PopperComponent={LeftBottomPopper}
                                    predicate={ruleAndThreshold.threshold}
                                    urls={ruleAndThreshold.urls}
                                    error={hasUniqueError ? "" : undefined}
                                    shouldUpdate={updateIfPropsChanged([
                                      "predicate",
                                      "urls",
                                      "error",
                                      "sortedList",
                                    ])}
                                    classes={{
                                      paper: classes.paper,
                                      option: classes.option,
                                    }}
                                    onFocus={() =>
                                      setFieldTouched(
                                        `rulesAndThresholds[${index}].report`,
                                        true,
                                      )
                                    }
                                    renderOption={(
                                      option: ReportOption,
                                      state: AutocompleteRenderOptionState,
                                    ) => {
                                      return (
                                        <ReportOptionElement
                                          key={option.code}
                                          option={option}
                                          state={state}
                                          maxSelection={1}
                                          group={option.category?.code}
                                          parentGroup={
                                            option.category?.parentName
                                          }
                                        />
                                      );
                                    }}
                                    noOptionsText={
                                      <span data-testid="report-combobox-no-options-text">
                                        {props.hasReportsError
                                          ? t("alerts:reportsError")
                                          : t("common:noOptions")}
                                      </span>
                                    }
                                    getOptionLabel={(option: ReportOption) => {
                                      if (
                                        option.code.startsWith(
                                          "custom_extraction_",
                                        )
                                      ) {
                                        return (
                                          sortedList.find(
                                            (e) => e.code === option.code,
                                          )?.name ?? option.name
                                        );
                                      }
                                      return option.name;
                                    }}
                                    getOptionSelected={(
                                      option: ReportOption,
                                      value: ReportOption,
                                    ) => option.code === value.code}
                                    style={{
                                      minWidth: REPORT_COMBOBOX_MIN_WIDTH,
                                      flex: 1,
                                      maxWidth: REPORT_COMBOBOX_MAX_WIDTH,
                                    }}
                                    onChange={async (
                                      _: unknown,
                                      o: ReportOption,
                                    ) => {
                                      if (!readOnly) {
                                        const nvalue =
                                          await calculateInitialThreshold(
                                            props.projectId,
                                            ruleAndThreshold.segment?.id,
                                            o.code,
                                            ruleAndThreshold.threshold,
                                          );
                                        setFieldValue(
                                          `rulesAndThresholds[${index}].urls`,
                                          nvalue,
                                        );
                                        setFieldTouched(
                                          `rulesAndThresholds[${index}].urls`,
                                          true,
                                        );
                                        setFieldTouched(
                                          `rulesAndThresholds[${index}].report`,
                                          true,
                                        );
                                        setFieldValue(
                                          `rulesAndThresholds[${index}].report`,
                                          o,
                                        );
                                      }
                                    }}
                                    renderGroup={(
                                      option: AutocompleteRenderGroupParams,
                                    ) => (
                                      <ReportOptionGroupElement
                                        key={option.key}
                                        group={option}
                                        categories={props.categories}
                                      />
                                    )}
                                    groupBy={(option: ReportOption) =>
                                      option.category?.parentName?.length
                                        ? option.category?.parentName
                                        : "SEO"
                                    }
                                    renderInput={(
                                      params: Exclude<
                                        AutocompleteRenderInputParams,
                                        "className"
                                      >,
                                    ) => (
                                      <MuiTextField
                                        {...params}
                                        className={classes.textField}
                                        name={`rulesAndThresholds[${index}].report`}
                                        error={hasUniqueError}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </TableCell>
                                {segmentationAvailable && (
                                  <TableCell className={classes.rowCell}>
                                    <FastField
                                      data-pendo="rule-and-threshold-segment-combobox"
                                      name={`rulesAndThresholds[${index}].segment`}
                                      size="small"
                                      component={SegmentComboBox}
                                      projectId={props.projectId}
                                      report={ruleAndThreshold.report}
                                      urls={ruleAndThreshold.urls}
                                      predicate={ruleAndThreshold.threshold}
                                      error={hasUniqueError}
                                      selected={ruleAndThreshold.segment}
                                      classes={{
                                        input: classes.segmentTextField,
                                      }}
                                      shouldUpdate={updateIfPropsChanged([
                                        "error",
                                        "predicate",
                                        "urls",
                                        "report",
                                        "projectId",
                                      ])}
                                      onChange={async (
                                        e: Pick<Segment, "id" | "name">,
                                      ) => {
                                        if (!readOnly) {
                                          const nvalue =
                                            await calculateInitialThreshold(
                                              props.projectId,
                                              e?.id,
                                              ruleAndThreshold.report.code,
                                              ruleAndThreshold.threshold,
                                            );
                                          setFieldValue(
                                            `rulesAndThresholds[${index}].urls`,
                                            nvalue,
                                          );
                                          setFieldTouched(
                                            `rulesAndThresholds[${index}].urls`,
                                            true,
                                          );
                                          setFieldTouched(
                                            `rulesAndThresholds[${index}].segment`,
                                            true,
                                          );
                                          setFieldValue(
                                            `rulesAndThresholds[${index}].segment`,
                                            e,
                                          );
                                        }
                                      }}
                                    />
                                  </TableCell>
                                )}
                                <TableCell className={classes.rowCell}>
                                  <ThresholdPredicateButtons
                                    isDisabled={readOnly}
                                    threshold={ruleAndThreshold.threshold}
                                    report={ruleAndThreshold.report}
                                    urls={ruleAndThreshold.urls}
                                    segmentId={ruleAndThreshold.segment?.id}
                                    onThresholdChange={async (newThreshold) => {
                                      if (!readOnly) {
                                        const cvalue =
                                          await calculateInitialThreshold(
                                            props.projectId,
                                            ruleAndThreshold.segment?.id,
                                            ruleAndThreshold.report.code,
                                            ruleAndThreshold.threshold,
                                          );
                                        if (cvalue === ruleAndThreshold.urls) {
                                          const nvalue =
                                            await calculateInitialThreshold(
                                              props.projectId,
                                              ruleAndThreshold.segment?.id,
                                              ruleAndThreshold.report.code,
                                              newThreshold,
                                            );
                                          setFieldValue(
                                            `rulesAndThresholds[${index}].urls`,
                                            nvalue,
                                          );
                                          setFieldTouched(
                                            `rulesAndThresholds[${index}].urls`,
                                            true,
                                          );
                                        }
                                        const fieldName = `rulesAndThresholds[${index}].threshold`;
                                        setFieldValue(fieldName, newThreshold);
                                        setFieldTouched(fieldName, true);
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  className={clsx(
                                    classes.rowCell,
                                    classes.thresholdCell,
                                  )}
                                >
                                  <Field
                                    className={classes.textField}
                                    component={TextFieldWithUnitField}
                                    disabled={readOnly}
                                    type="number"
                                    id={`rulesAndThresholds[${index}].urls`}
                                    name={`rulesAndThresholds[${index}].urls`}
                                    variant="outlined"
                                    size="small"
                                    data-testid="rule-and-threshold-url-input"
                                    data-pendo="rule-and-threshold-url-input"
                                    style={{
                                      maxWidth: 200,
                                      flexShrink: 100,
                                      minWidth: 80,
                                      width: "100%",
                                    }}
                                    onFocus={() =>
                                      setFieldTouched(
                                        `rulesAndThresholds[${index}].urls`,
                                        true,
                                      )
                                    }
                                    unit={t(
                                      `units:${
                                        ruleAndThreshold.report.unit ??
                                        ReportTemplateUnit.UrLs
                                      }`,
                                      { count: ruleAndThreshold.urls },
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  className={clsx(
                                    classes.rowCell,
                                    classes.issuesCell,
                                  )}
                                >
                                  <ThresholdAcceptanceMenu
                                    value={
                                      ruleAndThreshold.thresholdAcceptanceWhenBetter
                                    }
                                    onChange={(e) => {
                                      const fieldName = `rulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`;
                                      setFieldValue(fieldName, e.target.value);
                                      setFieldTouched(fieldName, true);
                                    }}
                                    disabled={readOnly}
                                    label={t(
                                      "rulesAndThresholds.thresholdSettingWhenBetter",
                                    )}
                                    subheader={t(
                                      "rulesAndThresholds.ifIssueImproves",
                                    )}
                                    data-pendo={
                                      "threshold-acceptance-when-better"
                                    }
                                    data-testid={
                                      "threshold-acceptance-when-better"
                                    }
                                  />
                                </TableCell>

                                <TableCell
                                  className={clsx(
                                    classes.rowCell,
                                    classes.issuesCell,
                                  )}
                                >
                                  <ThresholdAcceptanceMenu
                                    value={
                                      ruleAndThreshold.thresholdAcceptanceWhenWorse
                                    }
                                    onChange={(e) => {
                                      const fieldName = `rulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`;
                                      setFieldValue(fieldName, e.target.value);
                                      setFieldTouched(fieldName, true);
                                    }}
                                    disabled={readOnly}
                                    label={t(
                                      "rulesAndThresholds.thresholdSettingWhenWorse",
                                    )}
                                    subheader={t(
                                      "rulesAndThresholds.ifIssueWorsens",
                                    )}
                                    data-pendo={
                                      "threshold-acceptance-when-worse"
                                    }
                                    data-testid={
                                      "threshold-acceptance-when-worse"
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes.rowCell}>
                                  <div className={classes.reportTrendWrapper}>
                                    {ruleAndThreshold.report?.code ? (
                                      <ErrorBoundary
                                        fallbackUI={SimpleFallbackError}
                                      >
                                        <AnalyticsHubReportLink
                                          accountId={accountId}
                                          projectId={props.projectId}
                                          segmentId={
                                            ruleAndThreshold.segment?.id
                                          }
                                          crawlId={props.crawlId ?? ""}
                                          reportTemplateCode={
                                            ruleAndThreshold.report.code ?? ""
                                          }
                                          data-testid={`account-overview-grid-${ruleAndThreshold.report.code}-column-cell`}
                                          data-pendo="monitor-dashboard-report-link"
                                          className={classes.link}
                                        >
                                          <ReportTrendVisualisation
                                            reportCode={
                                              ruleAndThreshold.report.code
                                            }
                                            unit={
                                              ruleAndThreshold.report.unit ??
                                              ReportTemplateUnit.UrLs
                                            }
                                            projectId={props.projectId}
                                            segmentId={
                                              ruleAndThreshold.segment?.id
                                            }
                                            yAxisLineValue={
                                              ruleAndThreshold.urls
                                                ? ruleAndThreshold.urls
                                                : undefined
                                            }
                                          />
                                        </AnalyticsHubReportLink>
                                      </ErrorBoundary>
                                    ) : (
                                      <span>
                                        {t("alerts:noReportSelected")}
                                      </span>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell className={classes.rowCell}>
                                  {values.rulesAndThresholds.length > 1 ? (
                                    <HideFromInsufficientRole>
                                      <Tooltip title="Remove" arrow={false}>
                                        <IconButton
                                          className={classes.iconButton}
                                          size="small"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setFieldTouched(
                                              "rulesAndThresholds",
                                              true,
                                            );
                                          }}
                                          data-testid="remove-rule-and-threshold"
                                          data-pendo="remove-rule-and-threshold"
                                          aria-label={t("alerts:deleteTest")}
                                        >
                                          <TrashSolid />
                                        </IconButton>
                                      </Tooltip>
                                    </HideFromInsufficientRole>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                              {hasUniqueError ? (
                                <TableRow
                                  style={{
                                    height: 25,
                                    boxShadow:
                                      "0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset",
                                  }}
                                >
                                  <TableCell
                                    colSpan={9}
                                    style={{
                                      background: theme.palette.red[100],
                                      height: 25,
                                      padding: 0,
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: theme.spacing(1.5) }}
                                      variant="captionSemiBold"
                                    >
                                      {t("common:error")}
                                    </Typography>
                                    {": "}
                                    <Typography variant="caption">
                                      {reportsErrorMessage}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ) : undefined}
                            </React.Fragment>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </div>
                <HideFromInsufficientRole>
                  <ConditionallTooltip
                    interactive
                    title={
                      <Trans
                        ns="alerts"
                        i18nKey={
                          admin
                            ? "accountLimitReached"
                            : "accountLimitReachedEditor"
                        }
                        components={{
                          subscriptionLink: (
                            <a
                              href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                                accountId,
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          ),
                        }}
                      />
                    }
                    show={accountLimitReached}
                  >
                    <span>
                      <Button
                        className={classes.button}
                        type="button"
                        color="inherit"
                        size="large"
                        data-testid="add-rule-and-threshold-button"
                        data-pendo="add-rule-and-threshold-button"
                        startIcon={
                          <PlusSolid
                            htmlColor={
                              !Boolean(possibleTemplateList.length) ||
                              values.rulesAndThresholds.length >=
                                MAX_NUMBER_OF_RULES ||
                              disableAddButton
                                ? theme.palette.grey[400]
                                : theme.palette.primary.main
                            }
                          />
                        }
                        disableElevation
                        disabled={
                          !Boolean(possibleTemplateList.length) ||
                          values.rulesAndThresholds.length >=
                            MAX_NUMBER_OF_RULES ||
                          disableAddButton
                        }
                        onClick={async () => {
                          const reportTemplate = addPossibleTemplateList[0];

                          const sign = reportTemplate?.totalSign
                            ? reportTemplate?.totalSign
                            : -1;
                          arrayHelpers.push({
                            ...DEFAULT_RULE_AND_THRESHOLDS.find((e) =>
                              e.supportedModules?.includes(
                                props.moduleCode ?? ModuleCode.Seo,
                              ),
                            ),
                            report: reportTemplate,
                            threshold:
                              sign <= 0
                                ? ThresholdPredicate.GreaterThanOrEqual
                                : ThresholdPredicate.LessThan,
                            urls: await calculateInitialThreshold(
                              props.projectId,
                              undefined,
                              reportTemplate.code,
                            ),
                          });
                          setScrollToBottom(true);
                          setFieldTouched("rulesAndThresholds", true);
                        }}
                      >
                        {t("alerts:addRule")}
                      </Button>
                    </span>
                  </ConditionallTooltip>
                </HideFromInsufficientRole>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      minWidth: URL_THRESHOLD_WIDTH,
      padding: "0!important",
    },
    segmentTextField: {
      "& input::placeholder": {
        color: theme.palette.grey[800],
      },
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
        color: theme.palette.grey[800],
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      color: theme.palette.grey[800],
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    readOnly: {
      pointerEvents: "none",
    },
    iconButton: {
      marginTop: 2,
      borderRadius: 6,
      "& svg": {
        fontSize: 18,
        color: theme.palette.grey[400],
      },
      "&:hover": {
        backgroundColor: theme.palette.red[100],
        "& svg": {
          color: theme.palette.red[600],
        },
      },
    },
    rowContainer: {},
    button: {
      marginTop: 18,
      "&:not(.Mui-disabled) svg": {
        color: theme.palette.primary.main,
      },
    },
    reportTrendWrapper: {
      marginLeft: "auto",
    },
    switchRoot: {
      height: 19,
      width: 33,
      marginRight: "auto",
    },
    switchBase: {
      transform: "translate(-1px, -1.5px)",
      "& svg": {
        fontSize: 19,
      },
      "&.Mui-checked": {
        transform: "translate(11.5px, -1.5px)",
      },
    },
    group: {
      position: "sticky",
      top: -theme.spacing(1.2),
      display: "flex",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.grey[100],
      height: 40,
      zIndex: theme.zIndex.modal + 1,
    },
    icon: {
      color: theme.palette.purple[400],
      width: 20,
      height: 20,
      marginLeft: theme.spacing(2),
    },
    paper: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    scrollableContainer: {
      ["@media (max-width: 1400px)"]: {
        overflowX: "scroll",
      },
    },
    option: {
      padding: 0,
      "&[aria-disabled='true']": {
        pointerEvents: "all!important",
        cursor: "default",
      },
      "&[aria-selected='true']": {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.ultraviolet[200],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    table: {
      color: theme.palette.grey[700],
      tableLayout: "auto",
    },
    tableRow: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      height: 56,
    },
    centeredRowCell: {
      textAlign: "center",
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    },
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    changeHeader: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      width: 205,
      maxWidth: 205,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    severityCell: {
      width: 60,
      maxWidth: 93,
    },
    ruleCell: {
      width: 50,
      maxWidth: 83,
    },
    wrapper: {
      overflow: "auto",
      borderRadius: theme.spacing(1, 1, 0, 0),
      border: `1px solid ${theme.palette.grey[300]}`,
      borderCollapse: "unset",
      background: theme.palette.background.paper,
    },
    grouppedHeaderCell: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    thresholdCell: {
      width: "12%",
    },
    issuesCell: {
      background: "#EBEFF3",
    },
    link: {
      textDecoration: "none",
    },
  }),
);
